<template>
  <div class="page">
      <div style="width: 49%">
          <div>
              <el-button @click="reRewrite('rewrite')">重写</el-button>
              <el-button @click="reRewrite('summarize')">概述</el-button>
          </div>
          <el-input type="textarea" :rows="30" style="margin-top: 15px" v-model="text"></el-input>
      </div>
      <div style="width: 49%">
          <el-button @click="isCn = !isCn">中/英</el-button>
          <el-input type="textarea" :rows="30" style="margin-top: 15px" v-model="isCn?aiData.text_cn:aiData.text"></el-input>
          <div v-if="aiData.seo" style="margin-top: 10px;font-size: 15px">
              <span style="font-weight: bold">SEO</span>
              <div style="margin-top: 5px">
                  <span style="font-weight: bold;font-size: 13px">Title : </span>
                  <span>{{aiData.seo.title}}</span>
              </div>
              <div style="margin-top: 5px">
                  <span style="font-weight: bold;font-size: 13px">Keyword : </span>
                  <span>{{aiData.seo.keyword}}</span>
              </div>
              <div style="margin-top: 5px">
                  <span style="font-weight: bold;font-size: 13px">Descr : </span>
                  <span>{{aiData.seo.descr}}</span>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import {apiAIRewrite} from "@/request/api";

export default {
    name: "translateIndex",
    data(){
        return {
            text:'',
            aiData:{
                text:'',
                text_cn:'',
                seo:''
            },
            isCn:false
        }
    },
    methods:{
        reRewrite(opt){

            apiAIRewrite({text:this.text,opt}).then(res => {
                if(res.code == 200){
                    if (opt == 'rewrite'){
                        this.aiData = {
                            text:res.data.rewrite,
                            text_cn:res.data.rewrite_cn,
                            seo: {
                                title:res.data.seo_title,
                                keyword:res.data.seo_keyword,
                                descr:res.data.seo_description
                            }
                        }
                    }
                    if (opt == 'summarize'){
                        this.aiData = {
                            text:res.data.summarize,
                            text_cn:res.data.summarize_cn,
                            seo:''
                        }
                    }
                }else{
                    this.$message.error(res.message)
                }
            })
        }
    }
}
</script>
<style scoped>
.page {
    background-color: #FFF;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}
</style>